<template>
  <div>
    <VRow>
      <VCol class="py-0" cols="6">
        <div>
          <p class="form-title mb-2">
            Категория
          </p>
          <VAutocomplete
            v-model="selectedCategory"
            append-icon="mdi-chevron-down"
            attach
            class="form-field autocomplete-width"
            color="success"
            deletable-chips
            hide-selected
            item-text="title"
            :items="foodTypes"
            multiple
            no-data-text="Категория не найдена"
            outlined
            placeholder="Введите название или выберите"
            prepend-inner-icon="mdi-magnify"
            return-object
            :rules="[() => !!selectedCategory.length || 'Введите или выберите категорию']"
            small-chips
          />
        </div>
        <div>
          <p class="form-title my-2">
            Модификация
          </p>
          <VAutocomplete
            v-model="selectedModification"
            append-icon="mdi-chevron-down"
            attach
            class="form-field autocomplete-width"
            color="success"
            deletable-chips
            :disabled="!foodToEdit.isOption"
            hide-selected
            item-text="title"
            :items="modifiers"
            multiple
            no-data-text="Модификация не найдена"
            outlined
            placeholder="Введите название или выберите"
            prepend-inner-icon="mdi-magnify"
            return-object
            small-chips
          />
        </div>
        <div>
          <p class="form-title my-2">
            Название
          </p>
          <VTextField
            v-model="foodToEdit.title"
            class="form-field type-text"
            color="success"
            outlined
            placeholder="Например: Греческий салат"
            :rules="rules"
          />
        </div>
        <div>
          <p class="form-title my-2">
            Описание
          </p>
          <VTextarea
            v-model="foodToEdit.description"
            class="form-field type-text"
            color="success"
            outlined
            placeholder="Введите описание"
            rows="6"
          />
        </div>
        <div v-if="editable" class="my-2">
          <p class="form-title mb-0">
            Статус блюда
          </p>
          <StatesChipGroup
            :state="foodToEdit.state"
            @change="foodToEdit.state = $event"
          />
        </div>
      </VCol>
      <VCol class="py-0" cols="6">
        <div class="d-flex">
          <VCol class="py-0 pl-0" cols="6">
            <p class="form-title mb-2">
              Цена
            </p>
            <VTextField
              v-model="foodToEdit.price"
              class="form-field price"
              color="success"
              hide-details
              outlined
              :rules="[(value) => !!value || 'Введите цену']"
              @keypress="parseNumber"
            >
              <template #append>
                <span class="font-weight-medium"> &#8376; </span>
              </template>
            </VTextField>
          </VCol>
          <VCol class="py-0 pr-0" cols="6">
            <p class="form-title mb-2">
              Позиция
            </p>
            <VTextField
              class="form-field my-0"
              color="success"
              hide-details
              outlined
              :value="foodToEdit.position"
              @input="foodToEdit.position = $event !== '' ? $event : 0"
              @keypress="parseNumber"
            />
          </VCol>
        </div>
        <div class="d-flex align-center">
          <VCheckbox
            v-model="foodToEdit.isCommissionFree"
            class="light-grey-checkbox mt-0 mb-1 black--text"
            color="success"
            hide-details
            :ripple="false"
          />
          <span class="subtitle-1"> Не учитывать комиссию </span>
        </div>
        <div class="mt-4 mb-5">
          <TypeChipGroup
            :is-selected="foodToEdit.isOption"
            type="food"
            @change="foodToEdit.isOption = $event"
          />
        </div>
        <div>
          <ImageUpload
            :image="foodToEdit.image"
            image-type="food"
            @update="imageChanged"
          />
        </div>
      </VCol>
    </VRow>
    <VDivider />
    <VRow class="mt-6 mb-8">
      <div class="mx-3">
        <p class="form-title mb-2">
          Выбрать модификатор
        </p>
        <FoodModifiersSelection
          :modifiers="modifiers"
          :selected="foodToEdit.modifiers"
          @update:selected="updateModifiers"
        />
      </div>
    </VRow>
    <VRow>
      <TransitionGroup
        class="w-full mx-3"
        name="flip-list"
        tag="div"
      >
        <li
          v-for="(item, i) in foodToEdit.modifiers"
          :key="item + i"
          class="item"
          :class="{
            over: (item === over.item && item !== dragFrom),
            [over.dir]: (item === over.item && item !== dragFrom),
          }"
          :draggable="draggable"
          @dragend="(e) => finishDrag(item, i, e)"
          @dragover="(e) => onDragOver(item, i, e)"
          @dragstart="(e) => startDrag(item, i, e)"
        >
          <VDivider v-show="i > 0"/>
          <div class="d-flex">
            <div class="draggable mr-10 mt-6">
              <VImg
                contain
                height="22"
                :src="dragndropIcon"
                width="24"
                @mouseleave="draggable = false"
                @mouseover="draggable = true"
              />
            </div>
            <div class="w-full">
              <ModifierExtended
                :key="key + item.id"
                deletable
                :modifier="item"
                :selected-modifier="item"
                @delete="deleteFoodModifier(i)"
                @save="foodToEdit.modifiers[i] = $event"
              />
            </div>
          </div>
        </li>
      </TransitionGroup>
    </VRow>
    <VDivider class="mt-6 mb-6" />
    <VRow>
      <div class="mx-3">
        <p class="form-title mb-2">
          Метка
        </p>
        <VAutocomplete
          v-model="selectedLabels"
          append-icon="mdi-chevron-down"
          attach
          class="form-field autocomplete-width"
          color="success"
          deletable-chips
          hide-selected
          item-text="title"
          :items="labels"
          :menu-props="menuProps"
          multiple
          no-data-text="Метка не найдена"
          outlined
          placeholder="Введите название или выберите"
          prepend-inner-icon="mdi-magnify"
          return-object
          small-chips
        />
      </div>
    </VRow>
    <VDivider class="mt-5 mb-7" />
  </div>
</template>

<script>
import { fetchFoodTypes, fetchModifiers, fetchFoodLabels } from '@/api/api';
import { parseNumber } from '@/helpers/price';
import ImageUpload from '@/components/FoodImageUploader.vue';
import ModifierExtended from '@/components/ModifierExtended.vue';
import StatesChipGroup from '@/components/StatesChipGroup.vue';
import TypeChipGroup from '@/components/TypeChipGroup.vue';
import dragndropIcon from '@/assets/icons/dragndrop.svg';
import FoodModifiersSelection from '@/components/FoodModifiersSelection.vue';

export default {
  components: {
    FoodModifiersSelection,
    TypeChipGroup,
    StatesChipGroup,
    ModifierExtended,
    ImageUpload,
  },
  props: {
    restaurantPk: {
      type: [Number, String],
      default: null,
    },
    food: {
      type: Object,
      default: () => ({}),
    },
    foods: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      foodTypes: [],
      foodToEdit: {
        title: '',
        price: '',
        description: '',
        state: 'active',
        image: '',
        isFood: true,
        isOption: false,
        isAlcohol: false,
        position: 0,
        categories: [],
        categoryId: '',
        modifiers: [],
        labels: [],
      },
      modifiers: [],
      selectedModification: [],
      selectedCategory: [],
      key: 0,
      rules: [
        (value) => !!value || 'Введите название',
        (value) => !this.alreadyExists(value) || 'Блюдо с таким названием уже существует',
      ],
      parseNumber,
      selectedLabels: [],
      labels: [],
      menuProps: {
        offsetY: true,
        top: true,
      },
      dragndropIcon,
      over: {},
      startLoc: 0,
      dragging: false,
      draggable: false,
      dragFrom: {},
    };
  },
  async created() {
    this.modifiers = await fetchModifiers(this.restaurantPk);
    this.foodTypes = await fetchFoodTypes(this.restaurantPk);
    await fetchFoodLabels({ limit: 5000 }).then((response) => {
      this.labels = response.data;
    });
  },
  mounted() {
    if (Object.keys(this.food).length) {
      this.foodToEdit = { ...JSON.parse(JSON.stringify(this.food)) };
      if (this.foodToEdit.categories.length > 0) {
        this.selectedCategory = this.foodToEdit.categories.filter((item) => item.type === 'category');
        this.selectedModification = this.foodToEdit.categories.filter((item) => item.type === 'modifier');
      }
      if (this.foodToEdit.labels.length > 0) {
        this.selectedLabels = this.foodToEdit.labels;
      }
      const hasNullPosition = this.foodToEdit.modifiers.filter((item) => item.position === null);
      if (hasNullPosition.length) { this.updateModifierPosition(); }
    }
  },
  methods: {
    updateModifiers(modifiers) {
      this.foodToEdit.modifiers = modifiers;
      this.updateModifierPosition();
      this.key += 1;
    },
    deleteFoodModifier(index) {
      this.foodToEdit.modifiers.splice(index, 1);
    },
    imageChanged(imagePath) {
      this.foodToEdit.image = imagePath;
    },
    alreadyExists(title) {
      return this.foods.some((food) => food.title === title);
    },
    setCategoryId() {
      const categoriesIds = this.food.categories.map((category) => category.category_id);
      const indexOfFoodCategoryInFoodTypes = this.foodTypes.findIndex(
        (foodType) => categoriesIds.includes(foodType.id),
      );
      this.foodToEdit.categoryId = this.foodTypes[indexOfFoodCategoryInFoodTypes]?.id;
    },
    startDrag(item, e) {
      this.startLoc = e.clientY;
      this.dragging = true;
      this.dragFrom = item;
    },
    finishDrag(item, pos) {
      this.foodToEdit.modifiers.splice(pos, 1);
      this.foodToEdit.modifiers.splice(this.over.pos, 0, item);
      this.over = {};
      this.updateModifierPosition();
    },
    onDragOver(item, pos, e) {
      const dir = (this.startLoc < e.clientY) ? 'down' : 'up';
      setTimeout(() => {
        this.over = { item, pos, dir };
      }, 50);
    },
    updateModifierPosition() {
      if (this.foodToEdit.modifiers.length > 0) {
        const modifiers = [...[], ...this.foodToEdit.modifiers];
        modifiers.slice().reverse().forEach((element, index) => {
          // eslint-disable-next-line no-param-reassign
          element.position = index + 1;
        });
        this.foodToEdit.modifiers = modifiers;
      }
    },
  },
  watch: {
    foodToEdit: {
      handler(val) {
        this.$emit('change', val);
      },
      deep: true,
    },
    foodTypes: {
      deep: true,
      handler() {
        this.setCategoryId();
      },
    },
    selectedCategory: {
      handler(val) {
        this.foodToEdit.categories = val.concat(this.selectedModification);
      },
    },
    selectedModification: {
      handler(val) {
        if (this.foodToEdit.isOption) {
          this.foodToEdit.categories = val.concat(this.selectedCategory);
        }
      },
    },
    selectedLabels: {
      handler(val) {
        this.foodToEdit.labels = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: rgb(120,120,120);
  border-radius: 10px;
}
.draggable {
  &:hover {
    cursor: pointer;
  }
  span {
    width: 30px;
    font-size: 15px;
    line-height: 18px;
  }
}
.item {
  width: 100%;
  outline: none;
  display: inline-block;
  transition: opacity .3s ease-in-out;
}
</style>
