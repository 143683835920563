<template>
  <div>
    <VChipGroup
      v-model="selectedState"
      mandatory
    >
      <VChip
        v-for="(stateValue, index) in states[type]"
        :key="index"
        active-class="white--text"
        class="state-chip"
        :color="stateValue.color"
        :outlined="selectedState !== stateValue.value"
        :value="stateValue.value"
      >
        {{ stateValue.title }}
      </VChip>
    </VChipGroup>
  </div>
</template>

<script>
import { states } from '@/conditions/states';

export default {
  props: {
    state: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'food',
    },
  },
  emits: ['change'],
  data() {
    return {
      states,
      selectedState: null,
    };
  },
  mounted() {
    this.selectedState = this.state;
  },
  watch: {
    state(val) {
      this.selectedState = val;
    },
    selectedState: {
      handler(val) {
        this.$emit('change', val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

.active {
  color: #7263DA;
}

.sold_out {
  color: #B3B3BE;
}

.removed {
  color: #E43539;
}
</style>
