<template>
  <VDialog
    v-model="dialog"
    class="br-12"
    max-width="1049"
    @click:outside="close"
  >
    <template v-slot:activator="{ on, attrs }">
      <VSheet
        v-bind="attrs"
        v-on="on"
      >
        <slot name="header" />
      </VSheet>
    </template>
    <VCard class="pa-6">
      <div class="d-flex justify-space-between">
        <div class="d-flex align-center pt-2 pb-3">
          <span class="form-headline mr-2">{{ title }}</span>
          <slot name="form-headline" />
        </div>
        <RoundButton
          color="#E5E5E5"
          elevation="0"
          icon="mdi-close"
          icon-color="#565656"
          :max-height="30"
          :max-width="30"
          src=""
          @click="close"
        />
      </div>
      <VContainer class="px-5">
        <VForm ref="form">
          <slot class="pb-2 px-2" name="main" />
        </VForm>
        <VRow justify="center">
          <VBtn
            class="default-btn cancel-btn elevation-0 mr-3"
            color="#E0E0E0"
            x-large
            @click="close"
          >
            Отменить
          </VBtn>
          <VBtn
            class="default-btn save-changes-btn elevation-0"
            color="success"
            x-large
            @click="save"
          >
            Сохранить изменения
          </VBtn>
        </VRow>
      </VContainer>
    </VCard>
  </VDialog>
</template>

<script>
import RoundButton from '@/components/RoundButton.vue';

export default {
  components: { RoundButton },
  props: {
    title: {
      type: String,
      default: '',
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'update', 'add', 'open'],
  data() {
    return {
      dialog: false,
      key: 0,
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('close');
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.isEditing) {
          this.$emit('update');
        }
        this.$emit('add');
        this.close();
      }
    },
    incrementKey() {
      this.key += 1;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.incrementKey();
        this.$emit('open', this.key);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/menu";

</style>
