<template>
  <div class="d-flex my-6">
    <VIcon
      v-show="!deletable"
      class="mb-auto mr-4"
      :color="modifierToEdit.options.length ? 'success' : 'grey'"
      @click="updateSelected"
      v-text="modifierToEdit.options.length
        ? 'mdi-checkbox-marked': 'mdi-checkbox-blank-outline'"
    />
    <div class="d-flex w-full">
      <div class="mr-auto">
        <div class="d-flex mb-2">
          <p class="subtitle-1">
            {{ modifier.title ? modifier.title : modifier.categoryTitle }}
          </p>
          <div v-if="!isEditable" class="d-flex flex-wrap">
            <VChip
              class="mx-3 white--text mb-2"
              color="#048848"
              small
            >
              {{ modifierToEdit.isRequired ? 'Обязательный выбор' : 'Необязательный выбор' }}
            </VChip>
            <VChip
              class="mr-3 mb-2 white--text"
              color="#048848"
              small
            >
              {{ modifier.maxChoices > 1 ? 'Множественный выбор' : 'Единственный выбор' }}
            </VChip>
            <VChip
              class="mb-2"
              color="#FFEEEE"
              small
              text-color="#C84620"
            >
              Отключено: {{ removedOptionsCount }}
            </VChip>
            <VChip
              class="mb-2 ml-3"
              color="#F4F6FF"
              small
            >
              Не отображается: {{ invisibleOptionsCount }}
            </VChip>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <VBtn
            v-for="(option, index) in allModifierOptions"
            :key="index"
            :class="[
              'br-8 mr-3 mb-2 elevation-0',
              {'selected-btn': !deletable && selectedOptionIndex(option) > -1},
              {'disable-events': deletable },
              { 'bg-grey': option.isVisible === false },
              { 'turned-off': option.state === 'sold_out' || option.state === 'removed' },
            ]"
            height="38"
            outlined
            @click="updateOption(option)"
          >
            <span class="subtitle-1">
              {{ option.title }} ({{ option.price }}<span class="currency"> &#8376;</span>)
            </span>
          </VBtn>
        </div>
      </div>
      <div v-if="isDeletable" class="d-flex">
        <RoundButton
          class="mr-6"
          color="#C84620"
          @click="deleteModifier"
        />
        <RoundButton
          color="#048848"
          icon="mdi-pencil"
          src=""
          @click="openEdit"
        />
      </div>
      <VCard
        v-if="isEditable"
        class="d-flex"
        flat
        min-width="50%"
      >
        <div class="mr-5">
          <VRadioGroup
            v-model="modifierToEdit.isRequired"
            class="mt-0 pt-0"
            @change="modifierToEdit.minChoices = minByRequired[modifierToEdit.isRequired]"
          >
            <VRadio
              v-for="choice in choiceNecessityOptions"
              :key="choice.title"
              color="success"
              :label="choice.title"
              :value="choice.value"
            />
          </VRadioGroup>
        </div>
        <div>
          <VRadioGroup
            v-model="isMultiple"
            class="mt-0 pt-0"
            @change="modifierToEdit.maxChoices = isMultiple ? 2 : 1"
          >
            <VRadio
              v-for="choice in choiceQuantityOptions"
              :key="choice.id"
              color="success"
              :label="choice.title"
              :value="choice.value"
            />
          </VRadioGroup>
          <div v-show="isMultiple" class="pl-2">
            <p class="mb-2"> Диапазон выбора </p>
            <div class="d-flex justify-center">
              <VTextField
                v-model.number="modifierToEdit.minChoices"
                v-mask="'##'"
                class="type-choice"
                color="success"
                dense
                hide-details
                outlined
                placeholder="от"
                :readonly="!modifierToEdit.isRequired"
                @blur="validateMinChoices"
              />
              <VCard
                class="mx-3"
                flat
                width="22px"
              >
                <VDivider class="mt-5 hyphen" />
              </VCard>
              <VTextField
                v-model.number="modifierToEdit.maxChoices"
                v-mask="'##'"
                class="type-choice"
                color="success"
                dense
                hide-details
                :max="modifierToEdit.options.length"
                :min="isMultiple ? 2 : 1"
                outlined
                placeholder="до"
                @blur="validateChoices"
              />
            </div>
          </div>
        </div>
      </VCard>
      <RoundButton
        v-show="isSavable"
        color="#B7BED0"
        icon="mdi-check"
        src=""
        @click="closeEdit"
      />
    </div>
  </div>
</template>

<script>
import RoundButton from '@/components/RoundButton.vue';

export default {
  components: { RoundButton },
  props: {
    modifier: {
      type: Object,
      default: () => ({}),
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    selectedModifier: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['delete', 'save', 'remove', 'update'],
  data() {
    return {
      modifierToEdit: {
        id: this.modifier.id || null,
        title: this.modifier.title || '',
        position: this.modifier.position || 0,
        isRequired: true,
        minChoices: 1,
        maxChoices: 1,
        options: [],
      },
      isMultiple: false,
      isEditable: false,
      isDeletable: false,
      isSavable: false,
      choiceNecessityOptions: [
        { title: 'Обязательный выбор', value: true },
        { title: 'Необязательный выбор', value: false },
      ],
      choiceQuantityOptions: [
        { title: 'Единственный выбор', value: false },
        { title: 'Множественный выбор', value: true },
      ],
      minByRequired: {
        true: 1,
        false: 0,
      },
      allModifierOptions: this.modifier.options || [],
    };
  },
  mounted() {
    if (Object.keys(this.selectedModifier).length) {
      const selectedModifier = { ...JSON.parse(JSON.stringify(this.selectedModifier)) };
      this.modifierToEdit.minChoices = selectedModifier.minChoices;
      this.modifierToEdit.maxChoices = selectedModifier.maxChoices;
      this.modifierToEdit.options = selectedModifier.options;
      this.modifierToEdit.isRequired = selectedModifier.isRequired;
      this.isMultiple = this.modifierToEdit.maxChoices > 1;
    }
    this.isEditable = this.editable;
    this.isDeletable = this.deletable;
  },
  computed: {
    removedOptionsCount() {
      const removedOptions = this.modifierToEdit.options.filter(
        (option) => option.state !== 'active',
      );
      return removedOptions.length;
    },
    invisibleOptionsCount() {
      const invisibleOptions = this.modifierToEdit.options.filter(
        (option) => option.isVisible === false,
      );
      return invisibleOptions.length;
    },
    selectedOptions() {
      return this.modifierToEdit.options;
    },
  },
  methods: {
    selectedOptionIndex(option) {
      return this.modifierToEdit.options
        .findIndex((modifierOption) => modifierOption.id === option.id);
    },
    updateOption(option) {
      const index = this.selectedOptionIndex(option);
      if (index > -1) {
        this.modifierToEdit.options.splice(index, 1);
      } else {
        this.modifierToEdit.options.push(option);
      }
    },
    updateSelected() {
      if (this.modifierToEdit.options.length) {
        this.modifierToEdit.options = [];
      } else {
        this.modifierToEdit.options = [...JSON.parse(JSON.stringify(this.allModifierOptions))];
      }
    },
    deleteModifier() {
      this.$emit('delete');
    },
    openEdit() {
      this.isEditable = true;
      this.isDeletable = false;
      this.isSavable = true;
    },
    closeEdit() {
      this.validateChoices();
      this.isEditable = false;
      this.isDeletable = true;
      this.isSavable = false;
      this.$emit('save', this.modifierToEdit);
    },
    validateMinChoices() {
      // eslint-disable-next-line max-len
      this.modifierToEdit.minChoices = this.modifierToEdit.minChoices < this.minByRequired[this.modifierToEdit.isRequired]
        ? this.minByRequired[this.modifierToEdit.isRequired]
        : this.modifierToEdit.minChoices;
    },
    validateChoices() {
      // eslint-disable-next-line max-len
      this.modifierToEdit.minChoices = this.modifierToEdit.maxChoices > this.modifierToEdit.minChoices
        ? this.modifierToEdit.minChoices
        : this.modifierToEdit.maxChoices;
    },
  },
  watch: {
    selectedOptions(options) {
      if (!options.length) {
        this.$emit('remove', this.modifierToEdit.id);
      }
    },
    modifierToEdit: {
      handler() {
        if (this.selectedOptions.length) {
          this.$emit('update', this.modifierToEdit);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

.br-8 {
  border: 1px solid #DFE4EF;
}

::v-deep {
  .v-text-field input {
    padding: 0;
    margin: 0;
  }
}

.currency {
  font-size: 14px;
  padding-left: 1px;
}
</style>
