<template>
  <DialogWindow
    title="Выбрать модификатор"
    @add="$emit('update:selected', selectedModifiers)"
    @open="key += 1"
  >
    <template v-slot:header>
      <VBtn
        class="br-8"
        color="success"
        height="38"
        outlined
      >
        <span class="black--text subtitle-1"> Выбрать модификатор </span>
      </VBtn>
    </template>
    <template v-slot:main>
      <VDivider />
      <VCard
        class="overflow-auto"
        flat
        max-height="67vh"
      >
        <VSheet
          v-for="(modifier, index) in modifiers"
          :key="index"
        >
          <VDivider v-show="index > 0" />
          <ModifierExtended
            :key="key + modifier.id"
            editable
            :modifier="modifier"
            selectable
            :selected-modifier="selectedModifiersById[modifier.id]"
            @remove="removeModifier"
            @update="updateModifier"
          />
        </VSheet>
      </VCard>
      <VDivider class="mb-6" />
    </template>
  </DialogWindow>
</template>

<script>
import DialogWindow from '@/components/DialogWindow.vue';
import ModifierExtended from '@/components/ModifierExtended.vue';

export default {
  components: {
    DialogWindow,
    ModifierExtended,
  },
  props: {
    modifiers: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:selected'],
  data() {
    return {
      selectedModifiers: [],
      key: 0,
    };
  },
  mounted() {
    this.selectedModifiers = [...JSON.parse(JSON.stringify(this.selected))];
  },
  computed: {
    selectedModifiersById() {
      const selected = [...JSON.parse(JSON.stringify(this.selected))];
      const selectedModifiersById = {};
      selected.forEach((modifier) => {
        selectedModifiersById[modifier.id] = modifier;
      });
      return selectedModifiersById;
    },
  },
  methods: {
    updateModifier(modifier) {
      const index = this.selectedModifiers.findIndex((selected) => selected.id === modifier.id);
      if (index === -1) {
        this.selectedModifiers.push(modifier);
      } else {
        this.selectedModifiers[index] = modifier;
      }
    },
    removeModifier(id) {
      const index = this.selectedModifiers.findIndex((selected) => selected.id === id);
      if (index !== -1) { this.selectedModifiers.splice(index, 1); }
    },
  },
  watch: {
    selected: {
      handler() {
        this.selectedModifiers = [...JSON.parse(JSON.stringify(this.selected))];
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

</style>
