<template>
  <div>
    <VMenu
      :close-on-content-click="clickClose"
      :disabled="disabled"
      max-height="500"
      nudge-top="30"
      offset-y
    >
      <template #activator="{ on, attrs }">
        <VBtn
          v-bind="attrs"
          class="d-flex justify-space-between white--text px-2"
          :color="color"
          elevation="0"
          max-height="30"
          min-width="119"
          v-on="on"
          @click="clickClose = false"
        >
          <VIcon
            v-if="prependIcon"
            dense
            v-text="prependIcon"
          />
          <span class="caption">
            {{ selectedItem ? selectedItem.title : 'Выберите значение' }}
          </span>
          <VIcon
            v-show="!disabled"
            dense
            v-text="'mdi-menu-down'"
          />
        </VBtn>
      </template>
      <VList dense nav>
        <VListItemGroup dark mandatory>
          <VTextField
            v-model="search"
            class="mt-0 pt-0 mb-0 pb-0"
            placeholder="Поиск меток"
            prepend-inner-icon="mdi-magnify"
          />
          <VListItem
            v-for="(item, index) in labels"
            :key="index"
            :class="[{'last-element': item.title === 'Без меток'}]"
            dense
            :ripple="false"
            :value="item"
            @click.prevent="updateSelectedItem(item)"
          >
            <VListItemTitle class="text-subtitle-1" v-text="item.title" />
          </VListItem>
        </VListItemGroup>
      </VList>
    </VMenu>
  </div>
</template>

<script>
export default {
  name: 'FoodLabelsSelect',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: '#7F89A4',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      selectedItem: { title: 'Без меток' },
      search: '',
      clickClose: false,
      noLabel: {
        title: 'Без меток',
        value: 'all',
      },
    };
  },
  computed: {
    labels() {
      const list = JSON.parse(JSON.stringify(this.items));
      if (this.search === '') {
        return list;
      }
      return list.filter((elm) => elm.title.toLowerCase().includes(this.search.toLowerCase()));
    },
  },
  mounted() {
    if (this.title) {
      this.setSelectedItem();
    }
  },
  methods: {
    setSelectedItem() {
      this.selectedItem = this.labels.find(
        (item) => item.title === this.title,
      );
    },
    updateSelectedItem(val) {
      this.clickClose = true;
      this.selectedItem = val;
    },
  },
  watch: {
    title() {
      this.setSelectedItem();
    },
    selectedItem: {
      handler(val) {
        this.$emit('change', val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .theme--light.v-list {
    background: #7F89A4;
    color: white;
    font-size: 10px;
  }
  .v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 30px;
  }
  .v-icon.v-icon--dense {
    font-size: 15px;
  }
  .last-element {
    border-top: 1px solid rgba(255, 255, 255, 0.7);;
    border-top-right-radius: inherit !important;
    border-top-left-radius: inherit !important;
  }
}
</style>
