<template>
  <VCard class="my-5 mx-8" flat>
    <div class="mb-5 d-flex">
      <span class="page_title">Меню заведения {{restaurant ? `- ${restaurant.title}` : ''}}</span>
    </div>
    <div class="input mb-6">
      <p class="input-title my-2">
        Поиск
      </p>
      <RestaurantSelection
        :clear-btn="true"
        input-placeholder="Введите название заведения"
        :outline-color="'#8c8c8c'"
        @restaurantSelected="menuFetch"
      />
    </div>
    <div v-if="restaurantPk">
      <div class="mb-10">
        <p class="input-title mb-2">Копирование</p>
        <div class="d-flex">
          <RestaurantSelection
            class="input"
            :clear-btn="true"
            input-placeholder="Введите название заведения"
            :outline-color="'#8c8c8c'"
            @restaurantSelected="setCopyFromRestaurantPk"
          />
          <VBtn
            class="white--text rounded-btn mx-5 px-7 subtitle-2"
            color="#4caf50"
            elevation="1"
            @click="copyMenu"
          >
            Скопировать меню
          </VBtn>
          <VCard
            flat
            height="50px"
            max-width="450px"
          >
            <p class="caption grey--text text--darken-1 pl-2">
              При копировании меню с родительского заведения в данное будут созданы и
              скопированы все категории, особенности и добавки
            </p>
          </VCard>
        </div>
      </div>
      <VDivider class="my-8" />
      <VSlideGroup
        v-model="model"
        mandatory
      >
        <VSlideItem
          v-for="(option, index) in selectOptions"
          :key="index"
          v-slot="{ active, toggle }"
          :value="option.value"
        >
          <VBtn
            class="mr-3 text-none br-12 subtitle-1"
            color="success"
            elevation="0"
            min-height="48"
            :outlined="!active"
            width="150"
            @click="toggle"
          >
            {{ option.title }}
          </VBtn>
        </VSlideItem>
      </VSlideGroup>
      <VExpandTransition>
        <VSheet>
          <FoodsTable
            v-show="model === 'foods'"
            :key="`foods-${key}`"
            :restaurant-pk="restaurantPk"
          />
          <FoodTypesTable
            v-show="model === 'foodTypes'"
            :key="`food-types-${key}`"
            :restaurant-pk="restaurantPk"
          />
          <CartEventsTable
            v-show="model === 'foodEvents'"
            :key="`cart-events-${key}`"
            :restaurant-pk="restaurantPk"
          />
        </VSheet>
      </VExpandTransition>
    </div>
  </VCard>
</template>

<script>
import { copyMenu, fetchRestaurant } from '@/api/api';
import RestaurantSelection from '@/components/RestaurantSelection.vue';
import FoodTypesTable from '@/components/FoodTypesTable.vue';
import FoodsTable from '@/components/FoodsTable.vue';
import CartEventsTable from '@/components/CartEventsTable.vue';

export default {
  components: {
    RestaurantSelection,
    FoodTypesTable,
    FoodsTable,
    CartEventsTable,
  },
  data() {
    return {
      restaurant: null,
      menu: null,
      receivedMenu: null,
      foodTypesAndModifiers: [],
      restaurantPk: '',
      copyFromRestaurantPk: null,
      model: 'foods',
      selectOptions: [
        { title: 'Все блюда', value: 'foods' },
        { title: 'Категории', value: 'foodTypes' },
        { title: 'События', value: 'foodEvents' },
      ],
      key: 0,
    };
  },
  beforeMount() {
    if (this.$route.query?.pk) {
      fetchRestaurant(this.$route.query?.pk).then((res) => {
        this.restaurant = res;
        this.restaurantPk = this.$route.query?.pk;
      });
    }
  },
  methods: {
    menuFetch(restaurant) {
      if (restaurant && restaurant.pk) {
        this.$router.push({ path: this.$route.path, query: { pk: restaurant.pk } });
      }
    },
    copyMenu() {
      copyMenu(this.restaurantPk, this.copyFromRestaurantPk).then(() => {
        this.key += 1;
      });
    },
    setCopyFromRestaurantPk(restaurant) {
      this.copyFromRestaurantPk = restaurant ? restaurant.pk : null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

*{
  scroll-behavior: smooth;
}

::v-deep{
  .col-12{
    padding-left: 0;
    padding-right: 0;
  }
  .v-btn,
  .v-chip{
    height: 40px !important;
  }
}

</style>
