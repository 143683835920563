<template>
  <VDataIterator
    hide-default-footer
    :items="sortedItemsPerPage"
    :items-per-page="15"
    no-data-text="Данные отсутствуют"
    no-results-text="Данные отсутствуют"
    :page.sync="page"
  >
    <template v-slot:header>
      <div class="mt-4 d-flex justify-space-between">
        <div class="input mr-12">
          <p class="input-title larger my-2">
            Поиск
          </p>
          <VAutocomplete
            v-model="search"
            append-icon="mdi-chevron-down"
            clearable
            color="#8c8c8c"
            dense
            item-text="title"
            item-value="id"
            :items="items"
            no-data-text="Категория не найдена"
            outlined
            placeholder="Введите название категории"
            prepend-inner-icon="mdi-magnify"
          />
        </div>
        <div class="mr-12">
          <p class="input-title larger my-2">
            Создание
          </p>
          <DialogWindow
            :title="dialogTitle"
            @add="addItem"
            @open="key = $event"
          >
            <template v-slot:header>
              <UniversalButton
                v-for="(btnTitle, index) in createBtnTitles"
                :key="index"
                class="mr-5 my-1"
                icon="plus-circle"
                outlined
                style="border-radius: 12px; padding: 0 30px"
                :text="btnTitle"
                x-large
                @click="dialogTitle = btnTitle"
              />
            </template>
            <template v-slot:main>
              <FoodType
                v-if="dialogTitle === 'Создать категорию'"
                :key="key"
                :food-types="items"
                @change="foodTypeOrModifierToEdit = $event"
              />
              <Modifier
                v-if="dialogTitle === 'Создать модификатор'"
                :key="key"
                :modifiers="items"
                :restaurant-pk="restaurantPk"
                @change="foodTypeOrModifierToEdit = $event"
              />
            </template>
          </DialogWindow>
        </div>
      </div>
      <div class="mt-2 category-table">
        <VRow class="mx-3">
          <VCol class="table-title pl-4" cols="1">
            id
          </VCol>
          <VCol class="table-title" cols="2">
            Название
          </VCol>
          <VCol class="table-title pl-4" cols="2">
            Статус
          </VCol>
          <VCol class="table-title pl-4" cols="2">
            Отображается в меню приложения
          </VCol>
          <VCol class="table-title pl-8" cols="2">
            Тип
          </VCol>
          <VCol class="table-title" cols="2">
            Изменения
          </VCol>
          <VCol class="table-title" cols="1">
            Позиция
          </VCol>
        </VRow>
        <VCard class="my-2" elevation="0">
          <VToolbar
            color="#B7BED0"
            dense
            elevation="0"
          >
            <VRow class="px-2">
              <VCol
                class="pl-2"
                cols="2"
                offset="1"
              >
                <DropDownMenu
                  :items="titleSortOptions"
                  prepend-icon="mdi-magnify"
                  :title="titleSortOptions[0].title"
                  width="90"
                  @change="titleSortDirection = $event.value"
                />
              </VCol>
              <VCol cols="2">
                <DropDownMenu
                  :items="states.all"
                  :title="selectedState.title"
                  @change="selectedState = $event"
                />
              </VCol>
              <VCol cols="2">
                <DropDownMenu
                  :items="states.visibleAll"
                  :title="selectedVisible.title"
                  @change="selectedVisible = $event"
                />
              </VCol>
              <VCol class="pl-8" cols="2">
                <DropDownMenu
                  :items="types"
                  :title="selectedType.title"
                  @change="selectedType = $event"
                />
              </VCol>
            </VRow>
          </VToolbar>
        </VCard>
      </div>
    </template>
    <template v-slot:default="props">
      <VRow class="mx-0 font-12 category-table">
        <VCol
          v-for="foodType in props.items"
          :key="foodType.id"
          cols="12"
        >
          <VCard
            class="br-20"
            outlined
            tile
          >
            <VRow class="px-5 py-5">
              <VCol
                class="pl-4 pr-0"
                cols="1"
                style="min-width: 63px"
              >
                {{ foodType.id }}
              </VCol>
              <VCol cols="2">
                <DialogWindow
                  is-editing
                  :title="getDialogTitle(foodType.isModifier)"
                  @open="key = $event"
                  @update="updateItem"
                >
                  <template v-slot:header>
                    <span class="title-pointer"> {{ foodType.title }} </span>
                  </template>
                  <template v-slot:main>
                    <Modifier
                      v-if="foodType.isModifier"
                      :key="key"
                      editable
                      :modifier="foodType"
                      :restaurant-pk="restaurantPk"
                      @change="foodTypeOrModifierToEdit = $event"
                    />
                    <FoodType
                      v-if="foodType.isCategory && !foodType.isModifier"
                      :key="key"
                      editable
                      :food-type="foodType"
                      @change="foodTypeOrModifierToEdit = $event"
                    />
                  </template>
                </DialogWindow>
              </VCol>
              <VCol cols="2">
                <DropDownMenu
                  :color="states.colors[foodType.state]"
                  :items="states.foodType"
                  :title="states.titles[foodType.state]"
                  @select="updateStateOfFoodType($event, foodType)"
                />
              </VCol>
              <VCol cols="2">
                <DropDownMenu
                  :color="getValueByKey('color',foodType.isVisible)"
                  :items="states.visible"
                  :title="getValueByKey('title',foodType.isVisible)"
                  @select="updateVisibleOfFoodType($event, foodType)"
                />
              </VCol>
              <VCol class="pl-10" cols="2">
                <span v-show="foodType.isCategory"> Категория </span>
                <span v-show="foodType.isCategory && foodType.isModifier"> / </span>
                <span v-show="foodType.isModifier"> Модификатор </span>
              </VCol>
              <VCol class="pl-4" cols="2">
                <VCard
                  :class="{ 'green--text font-weight-bold': foodType.isRecentlyUpdated}"
                  flat
                  width="80"
                >
                  {{ foodType.updatedDt ? foodType.updatedDt : foodType.createdDt }}
                </VCard>
              </VCol>
              <VCol class="d-flex align-center pl-4" col="1">
                <span v-if="foodType.position != null">{{ foodType.position }}</span>
              </VCol>
            </VRow>
          </VCard>
        </VCol>
      </VRow>
    </template>
    <template v-slot:footer>
      <VPagination
        v-model="page"
        color="#B7BED0"
        :length="totalPage"
        :total-visible="7"
      />
    </template>
  </VDataIterator>
</template>

<script>
import { states } from '@/conditions/states';
import { alphabeticalSortOptions, typeOptions, objectsAreSame } from '@/helpers/table';
import { stringSortByKey, numberSortByKey } from '@/helpers/sort';
import {
  fetchFoodTypesAndModifiers,
  createFoodTypeOrModifier,
  updateFoodTypeOrModifier,
  deleteFoodTypeOrModifier,
} from '@/api/api';
import DropDownMenu from '@/components/DropDownMenu.vue';
import UniversalButton from '@/components/UniversalButton.vue';
import DialogWindow from '@/components/DialogWindow.vue';
import Modifier from '@/components/Modifier.vue';
import FoodType from '@/components/FoodType.vue';

export default {
  props: {
    restaurantPk: {
      type: String,
      default: null,
    },
  },
  components: {
    FoodType,
    Modifier,
    DialogWindow,
    UniversalButton,
    DropDownMenu,
  },
  data() {
    return {
      states,
      itemsPerPage: [],
      items: [],
      restaurantPkToClone: '',
      foodTypeOrModifierToEdit: null,
      showDialog: false,
      search: null,
      page: 1,
      totalPage: 0,
      selectedState: { title: 'Все', value: null },
      selectedType: { title: 'Все', value: 'all' },
      selectedVisible: { title: 'Все', value: null },
      dialogTitle: null,
      createBtnTitles: [
        'Создать категорию',
        'Создать модификатор',
      ],
      titleSortOptions: alphabeticalSortOptions,
      titleSortDirection: 'descending',
      types: typeOptions.foodType,
      key: 0,
    };
  },
  mounted() {
    this.getItems();
  },
  computed: {
    params() {
      const params = {};
      params.categories = this.search;
      params.page = this.page;
      params.states = this.selectedState.value;
      params[this.selectedType.value] = true;
      params.is_visible = this.selectedVisible.value;
      return params;
    },
    sortedItemsPerPage() {
      const items = this.itemsPerPage;
      const sortedByTitleItems = items.sort(stringSortByKey('title')[this.titleSortDirection]);
      return sortedByTitleItems.sort(numberSortByKey('position').descending);
    },
  },
  methods: {
    getValueByKey(key, state) {
      const selected = states.visible.find((item) => item.value === state);
      return selected[key];
    },
    async getItems() {
      await fetchFoodTypesAndModifiers(this.restaurantPk, this.params).then((response) => {
        this.itemsPerPage = response.items;
        this.totalPage = response.total_page;
      });
      await fetchFoodTypesAndModifiers(this.restaurantPk, { limit: 5000 }).then((response) => {
        this.items = response.items;
      });
    },
    async addItem() {
      await createFoodTypeOrModifier(this.restaurantPk, this.foodTypeOrModifierToEdit);
      await this.getItems();
      this.foodTypeOrModifierToEdit = null;
    },
    async updateItem() {
      await updateFoodTypeOrModifier(this.restaurantPk, this.foodTypeOrModifierToEdit);
      await this.getItems();
      this.key += 1;
    },
    async deleteItem(id) {
      await deleteFoodTypeOrModifier(this.restaurantPk, id);
      await this.getItems();
    },
    getDialogTitle(isModifier) {
      return isModifier ? 'Редактировать модификатор' : 'Редактировать категорию';
    },
    async updateStateOfFoodType(state, foodtype) {
      if (state !== undefined && state.value !== foodtype.state) {
        const foodTypeUpdate = foodtype;
        foodTypeUpdate.state = state.value;
        await updateFoodTypeOrModifier(this.restaurantPk, foodTypeUpdate);
        await this.getItems();
      }
    },
    async updateVisibleOfFoodType(state, foodtype) {
      if (state !== undefined && state.value !== foodtype.isVisible) {
        const foodTypeUpdate = foodtype;
        foodTypeUpdate.isVisible = state.value;
        await updateFoodTypeOrModifier(this.restaurantPk, foodTypeUpdate);
        await this.getItems();
      }
    },
  },
  watch: {
    restaurantPk: {
      immediate: false,
      handler() {
        this.getItems();
      },
    },
    params: {
      immediate: false,
      handler(newVal, oldVal) {
        if (newVal.page === oldVal.page) {
          this.page = 1;
        }
        if (!objectsAreSame(newVal, oldVal)) {
          this.getItems();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

</style>
