export const stringSortByKey = (key) => ({
  descending: (a, b) => a[key].localeCompare(b[key]),
  ascending: (a, b) => b[key].localeCompare(a[key]),
});

export const numberSortByKey = (key) => ({
  ascending: (a, b) => {
    const keyA = Number(a[key]);
    const keyB = Number(b[key]);
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  },
  descending: (a, b) => {
    const keyA = Number(b[key]);
    const keyB = Number(a[key]);
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  },
});
