<template>
  <div class="d-flex align-center">
    <div>
      <p class="form-title mb-3">
        {{ title }}
      </p>
      <div>
        <img
          v-if="image || chosenImage"
          alt="food_image"
          class="image"
          :src="image || chosenImage"
        />
        <div v-else class="image dashed d-flex justify-center align-center br-8">
          <span class="green--text font-weight-bold"> Изображение </span>
        </div>
      </div>
      <VCol class="d-flex flex-wrap align-center px-0">
        <VTextField
          v-model="chosenImage"
          class="form-field mt-1 mr-3 w65"
          color="success"
          dense
          hide-details
          outlined
          placeholder="Ссылка или ->"
          required
        />
        <UploadButton
          ref="fileSelection"
          class="upload-btn"
          :color="buttonColor"
          :image-type="imageType"
          :ratio="ratio"
          :ratio-required="ratioRequired"
          @image-uploaded="chosenImage = $event"
          @progress="uploadProgress = $event"
        />
        <div class="caption grey--text text--darken-1 mt-3 w80 pr-12">
          {{ description }}
        </div>
      </VCol>
    </div>
  </div>
</template>

<script>
import { imageValidator } from '@/validators/imageValidator';
import UploadButton from '@/components/UploadButton.vue';

export default {
  props: {
    title: {
      type: String,
      default: 'Изображение блюда',
    },
    image: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: 'Скопируйте ссылку на месторасположение изображения блюда либо загрузите изображение в формате PNG, JPEG, JPG',
    },
    buttonColor: {
      type: String,
      default: 'green',
    },
    imageType: {
      type: String,
      required: true,
    },
    ratio: {
      type: String,
      default: '1:2',
    },
    ratioRequired: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UploadButton,
  },
  emits: ['update'],
  data() {
    return {
      chosenImage: this.image,
      uploadProgress: 0,
      validImage: true,
    };
  },
  watch: {
    image(newImage) {
      this.chosenImage = newImage;
    },
    chosenImage(newPath, oldPath) {
      if (newPath !== oldPath) {
        if (newPath.length > 0) {
          const trimmed = newPath.trim();
          imageValidator(trimmed, this.ratioRequired, this.ratio)
            .then(() => {
              this.$emit('update', trimmed);
              this.validImage = true;
              this.$refs.fileSelection.showTooltip = false;
            })
            .catch(() => {
              this.validImage = false;
              this.$refs.fileSelection.showTooltip = true;
            });
        } else {
          this.$emit('update', newPath);
          this.validImage = false;
          this.$refs.fileSelection.showTooltip = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

.v-btn--fab.v-size--small {
  height: 40px !important;
  width: 40px !important;
}

.w65 {
  max-width: 65%;
}

.image {
  max-width: 229px;
  height: 170px;
  object-fit: cover;
  width: 100%;
}

.dashed {
  background-image: repeating-linear-gradient(0deg, #4caf50, #4caf50 11px,
    transparent 11px, transparent 21px, #4caf50 21px),
  repeating-linear-gradient(90deg, #4caf50, #4caf50 11px,
      transparent 11px, transparent 21px, #4caf50 21px),
  repeating-linear-gradient(180deg, #4caf50, #4caf50 11px,
      transparent 11px, transparent 21px, #4caf50 21px),
  repeating-linear-gradient(270deg, #4caf50, #4caf50 11px,
      transparent 11px, transparent 21px, #4caf50 21px);
  background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  margin-bottom: 6.5px;
}
</style>
