<template>
  <div class="d-flex">
    <VChip
      class="br-50 mt-2 h-40px px-11"
      color="success"
    >
      {{ type === 'foodType' ? 'Категория' : 'Блюдо' }}
    </VChip>
    <VChipGroup
      v-model="selected"
      active-class="success"
      class="br-50 ml-4"
    >
      <VChip
        class="br-50 px-11 h-40px"
        :value="true"
      >
        {{ type === 'foodType' ? 'Модификатор' : 'Опция модификатора' }}
      </VChip>
    </VChipGroup>
  </div>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  emits: ['change'],
  data() {
    return {
      selected: false,
    };
  },
  mounted() {
    this.selected = this.isSelected;
  },
  watch: {
    isSelected(val) {
      this.selected = val;
    },
    selected(val) {
      this.$emit('change', val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

</style>
