<template>
  <VRow class="mx-0 mb-12">
    <VCol class="px-0 py-1" cols="7">
      <div class="d-flex">
        <VCol cols="8">
          <p class="form-title mb-2">
            Название категории
          </p>
          <VTextField
            v-model="foodTypeToEdit.title"
            class="form-field type-text"
            color="success"
            outlined
            placeholder="Введите название"
            :rules="rules"
          />
        </VCol>
        <VCol cols="4">
          <p class="form-title mb-2">
            Позиция
          </p>
          <VTextField
            class="form-field type-text"
            color="success"
            outlined
            :value="foodTypeToEdit.position"
            @input="foodTypeToEdit.position = $event !== '' ? $event : 0"
            @keypress="parseNumber"
          >
          </VTextField>
        </VCol>
      </div>
      <div v-if="editable">
        <p class="form-title mb-2">
          Статус категории
        </p>
        <StatesChipGroup :state="foodTypeToEdit.state" @change="foodTypeToEdit.state = $event" />
      </div>
    </VCol>
  </VRow>
</template>

<script>
import StatesChipGroup from '@/components/StatesChipGroup.vue';
import { parseNumber } from '@/helpers/price';

export default {
  components: {
    StatesChipGroup,
  },
  props: {
    foodType: {
      type: Object,
      default: () => ({}),
    },
    foodTypes: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      foodTypeToEdit: {
        title: '',
        state: 'active',
        isCategory: true,
        isModifier: false,
        options: [],
        position: 0,
      },
      rules: [
        (value) => !!value || 'Введите название',
        (value) => !this.alreadyExists(value) || 'Категория с таким названием уже существует',
      ],
      parseNumber,
    };
  },
  mounted() {
    if (Object.keys(this.foodType).length) {
      this.foodTypeToEdit = { ...this.foodType };
    }
  },
  methods: {
    alreadyExists(title) {
      return this.foodTypes.some((foodType) => foodType.title === title);
    },
  },
  watch: {
    foodTypeToEdit: {
      handler(val) {
        this.$emit('change', val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/menu.scss";

</style>
